import TrustMarkers from '../TrustMarkers'

const NewTrustMarkersWrapper = ({
  trustMarkersCombination,
  title,
  isDynamic,
}) => (
  <div className="px-6 pt-8 pb-2 lg:pt-12 lg:pb-6">
    {title && (
      <p className="text-center text-sm font-bold uppercase tracking-[.25em] text-[#002d87]">
        {title}
      </p>
    )}
    <div className="px-8 lg:px-0">
      <TrustMarkers
        trustMarkersCombination={trustMarkersCombination}
        isDynamic={isDynamic}
        isFullLink={false}
      />
    </div>
  </div>
)

export default NewTrustMarkersWrapper
